import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tagline from "../components/tagline"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Tagline content="404: Not Found"/>
    <div classname="wrapper" style={{ minHeight: '400px' }}>
        <p style={{fontSize: '30px', textAlign: 'center', marginTop: "30px"}}>Whoops, looks like you are trying to load a page that doesn't exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
